/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react"
import { useEffect, startTransition, StrictMode } from "react"
import { hydrateRoot } from "react-dom/client"
import * as Sentry from "@sentry/remix"

Sentry.init({
  dsn: window.ENV?.SENTRY_DSN,
  environment: window.ENV?.SENTRY_ENVIRONMENT,
  includeLocalVariables: true,
  tracesSampleRate: 0,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  )
})
